import styled from "styled-components"

// Utils
import breakpoint from "utils/breakpoints/"
import { colors } from "utils/variables/"

// Icons
import DownloadIcon from "assets/icons/icon-download.svg"

const StyledBodyLanding = styled.section`
 
 .flexBox {
  display: flex;
  flex-wrap: wrap;
  padding: 40px 20px 0px;
  column-gap: 30px;

  ${breakpoint.small`
    flex-wrap: nowrap;
  `}
}

  .block {
    width: 100%;
	text-align: center;
	padding-inline: 20px;

    ${breakpoint.small`
		width: 100%;
		padding-inline: 0;
		text-align:left;
    `}

    &.block--left {
		display: flex;
		flex-wrap: wrap;
		
		margin-bottom: 20px;
		
		${breakpoint.medium`
			flex-wrap: nowrap;
		`}
      
      .presenter__image {
      	max-width: 250px;
		margin: 10px auto 30px;

			${breakpoint.small`
				float: left;
				margin: 10px 35px 1% 0;
			`}
      }
      
      
    }

    &.block--right {
		max-width: 330px;
      	width: 100%;
      	padding-left: 30px;
      	margin: 0 auto;
		
		${breakpoint.small`
      		padding-top: 30px;
			border-left: 1px solid ${colors.charcoal};
			height: 280px;
		`}
	 }

		.presenterBio {
			display: flow-root;
			margin-bottom: 30px;
		}

		.presenter {
			width: 90%;
			margin: 0px auto;
			padding-left: 0px;
			padding-bottom: 60px;
			
				${breakpoint.small`
					width: 100%;
					margin: 0px;
					padding-inline: 20px;
				`}
			}

		h2 {
			margin-block: 4rem 1.4rem;
			text-align: center;

			:first-of-type { margin-block: 2rem 1.4rem; }

			${breakpoint.small`
				text-align: left;
			`}
		}

		h3 {
			font-size: 24px;
			color: ${colors.blue};
			margin-top: 20px;
			margin-bottom: 10px;
			text-align: center;
  
			${breakpoint.small`
				font-size: 30px;
				margin-top: 0px;
				text-align: left;
			`}
		}

		p {
			width: 100%;
			text-align: center;
			margin-bottom: 10px;

			${breakpoint.small`
				text-align: left;
				margin-bottom: 20px;
			`}
		}
	
			a {
				color: ${colors.blue};

				&:hover {
					color: ${colors.orange};
				}
			}

		.subtitle {
			font-weight: 600;
		}

		.footnote { 
			font-size: 15px; 
			line-height: 1.1rem;

			${breakpoint.small`
				font-size: 16px; 
				line-height: 1.2rem;
			`}
		}

		ul {
		  list-style: outside;
		  margin-bottom: 20px;
		  padding-left: 20px;
		  }
	
		li {
			text-align: center;
			margin-block: 2px;

			${breakpoint.small`
				text-align: left;
			`}
		}
	
  }
  
  .accessBox {
    	display: none;
  }
  .downloadBox {
    	display: none;
    
    	p {
    	
			a {
				color: ${colors.charcoal};

				&:hover {
					color: ${colors.orange};
				}
			}
    	
            &::before {
              content: url(${DownloadIcon}); 
              margin-right: 12px;   	
    		}
		  }
 	 }
  
  .show { display: block; }

  .showPDF { 
		display: ${props => (props.viewAllowed ? "block" : "none")};
   }
  
`

export default StyledBodyLanding
