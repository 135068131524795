import React from "react"

// Libraries
import { StaticImage } from "gatsby-plugin-image"
import styled from "styled-components"

// Components
import Container from "components/container"
import WebinarBody from "components/webinar/body"

// Downloads
import PresentationSlides from "assets/downloads/sexual-health-test/webinar-replay/2022_09_27-Visby Slides-Trent.pdf"

const StyledContainer = styled(Container)`
  max-width: 1100px;
  overflow: hidden;
  padding: 0px;
`

const BodyLanding = props => {

	return (
	<StyledContainer>
    	<WebinarBody
			viewAllowed={props.viewAllowed}
    	>
    	<div className="flexBox">
			<div className="block block--left">
				  <div className="block__content">
					<h2 data-aos="fade-up">Overview</h2>
					<p data-aos="fade-up">
					Adolescents often present with vague symptoms for sexually transmitted infections (STIs). 
					A majority are underrecognized and untreated at the time of presentation<sup>1</sup>. Also, a large proportion is 
					lost to follow-up<sup>1-2</sup>. This webinar will discuss testing methods for detecting common STIs and 
					patient education strategies to reduce recurrence.
					</p>
					<p className="footnote" data-aos="fade-up">
					<sup>1</sup> <a href="https://pubmed.ncbi.nlm.nih.gov/25154560/" target="_blank" rel="noopener noreferrer">https://pubmed.ncbi.nlm.nih.gov/25154560/</a><br />
					<sup>2</sup> <a href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC4074614/pdf/peds.2011-3326.pdf" target="_blank" rel="noopener noreferrer">https://www.ncbi.nlm.nih.gov/pmc/articles/PMC4074614/pdf/peds.2011-3326.pdf</a></p>
					<h2 data-aos="fade-up">Learning Objectives</h2>
					<p data-aos="fade-up" className="subtitle">In this video, participants will:</p>
					<ul data-aos="fade-up">
						<li>Review applicable CDC Screening Guidelines for common STIs in young females</li>
						<li>Examine the importance of sexual health education for sexually active adolescent girls</li>
						<li>Evaluate effective STI testing methods and sexual health education strategies</li> 
					</ul>
				  </div>
				</div>
				{ props.featured===true ? 
					null
					: (
			   <div className="block block--right">
				   <div className={ (props.pageName==='display' | props.viewAllowed===false ) ? "accessBox show" : "accessBox" }>
						<h3 data-aos="fade-up">Access Includes:</h3>
						<ul data-aos="fade-up">
							<li>Video recording replay</li>
							<li>Presentation Slides (PDF)</li>
						</ul>
					</div>
				   <div className={ (props.pageName==='download') ? "downloadBox show showPDF" : "downloadBox" }>
						<h3 data-aos="fade-up">Download</h3>
						<p data-aos="fade-up"><a href={PresentationSlides} target="_blank" rel="noopener noreferrer">Presentation Slides (PDF)</a></p>
					</div>
				</div>
					)
					}
			</div>

			<div className="block block--left">
			  <div className="block__content presenter">
				<h2  data-aos="fade-up">About the Presenter</h2>
				
				<div className="presenterBio" data-aos="fade-up">
				<StaticImage
					src="../../../../assets/images/webinar-replay/maria-trent.jpg"
					width={200}
					alt="Dr. Maria Trent"
					className="presenter__image"
				/>
				<p data-aos="fade-up">Dr. Trent currently serves as the Chair of the Section on Adolescent Health for the 
				American Academy of Pediatrics and is President-elect for the Society for Adolescent Health and Medicine. 
				A pediatric and adolescent medicine specialist on the faculty of the Johns Hopkins University Schools of 
				Medicine, Nursing, and Public Health, Dr. Trent is also the principal investigator for multiple research 
				projects funded by the National Institutes of Health and other agencies. A major focus of Dr. Trent’s 
				research and clinical interest has been on reducing adolescent and young adult sexual and reproductive
				health disparities. Dr Trent is a sought-after speaker and author of research articles, editorials, 
				book chapters, and patient-directed media materials.</p>
				</div>
			</div>
		</div>
    	</WebinarBody>
    </StyledContainer>
  )
}

class FullBody extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      viewAllowed: false
    }
  }

  componentDidMount = props=> {
  if(localStorage.getItem(this.props.localStorageID)) {
		this.setState({
				viewAllowed: true
			})
		}
	}

  render = () => (
    <BodyLanding
		pageName={this.props.pageName}
		viewAllowed={this.state.viewAllowed}
		localStorageID={this.props.localStorageID}
		featured={this.props.featured}
		featuredDate={this.props.featuredDate}
		featuredTime={this.props.featuredTime}
		featuredCost={this.props.featuredCost}
		featuredUrl={this.props.featuredUrl}
    />
  )
}

export default FullBody
